// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Toggle from "../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as $$Location from "../../../../models/Location.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as Api_Location from "../../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardExportModal from "../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as LocationsDashboardNavbar from "./components/navbar/LocationsDashboardNavbar.res.js";
import * as DashboardLocationsIndexScss from "./DashboardLocationsIndex.scss";
import * as DashboardLocationsIndexOptions from "./components/options/DashboardLocationsIndexOptions.res.js";
import * as ProviderEditLocationsActionBar from "../../../provider-edit/tabs/components/locations/components/ProviderEditLocationsActionBar.res.js";
import * as DashboardLocationsIndexProviderFilter from "./components/provider-filter/DashboardLocationsIndexProviderFilter.res.js";

var css = DashboardLocationsIndexScss;

var initialState_sortBy = {
  NAME: "Name",
  VAL: "Asc"
};

var initialState_selection = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState_isApprovedFilter = [];

var initialState_providerIds = [];

var initialState = {
  status: "FetchingLocations",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false,
  openDestroyConfirm: false,
  currentLocationId: undefined,
  isApprovedFilter: initialState_isApprovedFilter,
  providerIds: initialState_providerIds,
  csvModalOpen: false
};

function DashboardLocationsIndex$default(props) {
  var container = React.useRef(null);
  var searchLocations = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "RemoveLocation" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "FetchLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Location.dashboard(state.currentPage, tmp, state.sortBy, state.isApprovedFilter, state.providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardLocationsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardLocationsIndex.default"
                                          }, "DashboardLocationsIndex::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "FailLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var locations = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: locations.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(locations, (function ($$location) {
                                          return $$location.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var locations$1 = state.status;
                  if (typeof locations$1 !== "object") {
                    return "NoUpdate";
                  }
                  var locations$2 = locations$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var locationIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function ($$location) {
                                                          return Caml_obj.equal(id, $$location.id);
                                                        }), __x);
                                          })(locations$2);
                                      if (match !== undefined && !match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Location.activateInBatch({
                                        locationIds: locationIds,
                                        active: true
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: locationIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardLocationsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardLocationsIndex.default"
                                          }, "DashboardLocationsIndex" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var locations$3 = state.status;
                  if (typeof locations$3 !== "object") {
                    return "NoUpdate";
                  }
                  var locations$4 = locations$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var locationIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function ($$location) {
                                                          return Caml_obj.equal(id, $$location.id);
                                                        }), __x);
                                          })(locations$4);
                                      if (match !== undefined && match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Location.activateInBatch({
                                        locationIds: locationIds,
                                        active: false
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: locationIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardLocationsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardLocationsIndex.default"
                                          }, "DashboardLocationsIndex" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: false,
                            currentLocationId: undefined,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: [],
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "ToggleCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: !state.csvModalOpen
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLocationsFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.locations
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "CreatedAt") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "CreatedAt" && match$6.VAL === "Desc" ? ({
                          NAME: "CreatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "CreatedAt",
                          VAL: "Desc"
                        });
                  } else if (sort === "Approved") {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "Approved" && match$7.VAL !== "Desc" ? ({
                          NAME: "Approved",
                          VAL: "Desc"
                        }) : ({
                          NAME: "Approved",
                          VAL: "Asc"
                        });
                  } else if (sort === "ProviderName") {
                    var match$8 = state.sortBy;
                    tmp = typeof match$8 === "object" && match$8.NAME === "ProviderName" && match$8.VAL === "Desc" ? ({
                          NAME: "ProviderName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "ProviderName",
                          VAL: "Desc"
                        });
                  } else if (sort === "Name") {
                    var match$9 = state.sortBy;
                    tmp = typeof match$9 === "object" && match$9.NAME === "Name" && match$9.VAL === "Desc" ? ({
                          NAME: "Name",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Name",
                          VAL: "Desc"
                        });
                  } else if (sort === "LastApproved") {
                    var match$10 = state.sortBy;
                    tmp = typeof match$10 === "object" && match$10.NAME === "LastApproved" && match$10.VAL === "Desc" ? ({
                          NAME: "LastApproved",
                          VAL: "Asc"
                        }) : ({
                          NAME: "LastApproved",
                          VAL: "Desc"
                        });
                  } else {
                    var match$11 = state.sortBy;
                    tmp = typeof match$11 === "object" && match$11.NAME === "Active" && match$11.VAL === "Desc" ? ({
                          NAME: "Active",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Active",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: searchLocations
                        };
              case "PerformSearch" :
                  var match$12 = state.status;
                  if (typeof match$12 !== "object" && match$12 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$13 = state.batchUpdate;
                  if (match$13 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            }
                          };
                  }
              case "UpdateActive" :
                  var action$1 = action._1;
                  var locationId = action._0;
                  var match$14 = state.status;
                  var match$15 = state.batchUpdate;
                  var match$16 = Belt_Map.get(state.entityUpdates, locationId);
                  if (typeof match$14 !== "object") {
                    match$14 === "FetchingLocations";
                  } else if (match$15 === undefined && match$16 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$14._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, locationId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Active" ? true : false;
                                        return {
                                                id: x.id,
                                                name: x.name,
                                                city: x.city,
                                                state: x.state,
                                                country: x.country,
                                                active: tmp,
                                                isApproved: x.isApproved,
                                                url: x.url,
                                                providerId: x.providerId,
                                                providerName: x.providerName,
                                                providerSlug: x.providerSlug,
                                                lastApprovedOn: x.lastApprovedOn,
                                                createdAt: x.createdAt
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              entityUpdates: Belt_Map.set(state.entityUpdates, locationId, {
                                    TAG: "ActiveUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Active") {
                                  return $$Promise.wait(Api_Location.activate(locationId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: locationId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "DashboardLocationsIndex",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "DashboardLocationsIndex.default"
                                                    }, "DashboardLocationsIndex" + "::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: locationId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_Location.deactivate(locationId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: locationId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "DashboardLocationsIndex",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "DashboardLocationsIndex.default"
                                                    }, "DashboardLocationsIndex" + "::Deactivate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: locationId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedActiveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "FailActiveUpdate" :
                  var locationId$1 = action._0;
                  var match$17 = state.status;
                  var match$18 = Belt_Map.get(state.entityUpdates, locationId$1);
                  if (typeof match$17 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$18 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$18._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$17._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, locationId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Active" ? true : false;
                                      return {
                                              id: x.id,
                                              name: x.name,
                                              city: x.city,
                                              state: x.state,
                                              country: x.country,
                                              active: tmp,
                                              isApproved: x.isApproved,
                                              url: x.url,
                                              providerId: x.providerId,
                                              providerName: x.providerName,
                                              providerSlug: x.providerSlug,
                                              lastApprovedOn: x.lastApprovedOn,
                                              createdAt: x.createdAt
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, locationId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var locations$5 = state.status;
                  if (typeof locations$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  city: x.city,
                                                  state: x.state,
                                                  country: x.country,
                                                  active: true,
                                                  isApproved: x.isApproved,
                                                  url: x.url,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  lastApprovedOn: x.lastApprovedOn,
                                                  createdAt: x.createdAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var locations$6 = state.status;
                  if (typeof locations$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$6._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  city: x.city,
                                                  state: x.state,
                                                  country: x.country,
                                                  active: false,
                                                  isApproved: x.isApproved,
                                                  url: x.url,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  lastApprovedOn: x.lastApprovedOn,
                                                  createdAt: x.createdAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              isApprovedFilter: state.isApprovedFilter,
                              providerIds: state.providerIds,
                              csvModalOpen: state.csvModalOpen
                            }
                          };
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: true,
                            currentLocationId: Caml_option.some(action._0),
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "DestroyLocation" :
                  var providerId = action._1;
                  var id$1 = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Location.destroy(providerId, id$1), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("CloseDestroyConfirm");
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardLocationsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardLocationsIndex.default"
                                          }, "Destroy::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "ToggleApprovalFilter" :
                  var status = action._0;
                  var match$19 = state.status;
                  if (typeof match$19 !== "object" && match$19 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  var notStatus = function (s) {
                    return s !== status;
                  };
                  var isApprovedFilter = state.isApprovedFilter.includes(status) ? (function (__x) {
                          return __x.filter(notStatus);
                        })(state.isApprovedFilter) : state.isApprovedFilter.concat([status]);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: isApprovedFilter,
                            providerIds: state.providerIds,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            isApprovedFilter: state.isApprovedFilter,
                            providerIds: action._0,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchLocations");
        }), []);
  var x = state.isApprovedFilter.length;
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var locations = state.status;
  var tmp$1;
  if (typeof locations !== "object") {
    tmp$1 = false;
  } else {
    var locations$1 = locations._0;
    tmp$1 = Belt_Set.size(state.selection) === locations$1.length && locations$1.length !== 0;
  }
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var match$7 = state.sortBy;
  var locations$2 = state.status;
  var tmp$2;
  if (typeof locations$2 !== "object") {
    tmp$2 = locations$2 === "FetchingLocations" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var locations$3 = locations$2._0;
    tmp$2 = locations$3.length !== 0 ? Belt_Array.mapWithIndex(locations$3, (function (index, $$location) {
              var active = $$location.active;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Checkbox.make, {
                                        id: "location--" + (ID.toString($$location.id) + "--selected"),
                                        size: "SM",
                                        checked: Belt_Set.has(state.selection, $$location.id),
                                        onChange: (function (param) {
                                            dispatch({
                                                  TAG: "ToggleSelectionOfItem",
                                                  _0: $$location.id
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: $$location.name,
                                        className: css.tableLink,
                                        href: Routes_Location.Dashboard.edit($$location.id, $$location.providerSlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: $$location.providerName,
                                        className: css.tableLink,
                                        href: Routes_Provider.Dashboard.edit($$location.providerSlug)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: $$Location.isApprovedToString($$location.isApproved)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format($$location.lastApprovedOn, "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format($$location.createdAt, "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: active,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateActive",
                                                  _0: $$location.id,
                                                  _1: active ? "Inactive" : "Active"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: css.dropdownBodyLink,
                                                                    href: Routes_Location.Dashboard.edit($$location.id, $$location.providerSlug)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Delete",
                                                                    className: css.dropdownBodyLink,
                                                                    href: "#",
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "OpenDestroyConfirm",
                                                                              _0: $$location.id
                                                                            });
                                                                      })
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: css.blogPostMenu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No locations were found."
                })
          });
  }
  var locations$4 = state.status;
  var tmp$3;
  if (typeof locations$4 !== "object") {
    tmp$3 = null;
  } else {
    var locations$5 = locations$4._0;
    tmp$3 = JsxRuntime.jsx(ProviderEditLocationsActionBar.make, {
          selectionCount: Belt_Set.toArray(state.selection).length,
          activeSelection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function ($$location) {
                                                    return Caml_obj.equal(id, $$location.id);
                                                  }), __x);
                                    })(locations$5), (function ($$location) {
                                    return $$location.active;
                                  }));
                    })), [], (function (acc, active) {
                  if (active !== undefined) {
                    return Belt_Array.concat(acc, [active]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsDashboardNavbar.make, {
                      selectedCategory: "Locations"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(H1.make, {
                                    className: css.title,
                                    children: "Manage Locations"
                                  }),
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(SearchField.make, {
                                      id: "locations-search",
                                      value: state.search,
                                      placeholder: "Search by name, city, state, or country",
                                      inputClassName: css.searchField,
                                      onChange: (function ($$event) {
                                          dispatch({
                                                TAG: "UpdateSearchInput",
                                                _0: $$event.target.value
                                              });
                                        })
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(DashboardLocationsIndexProviderFilter.make, {
                                              updateProviderIds: (function (ids) {
                                                  return function (param) {
                                                    dispatch({
                                                          TAG: "UpdateProviderIds",
                                                          _0: ids
                                                        });
                                                  };
                                                }),
                                              reset: (function () {
                                                  dispatch("Reset");
                                                })
                                            }),
                                        JsxRuntime.jsxs(Dropdown.make, {
                                              children: [
                                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                      className: css.dropdown,
                                                      children: x !== 0 ? (
                                                          x !== 1 ? String(x) + " Statuses" : "1 Status"
                                                        ) : "Approval Status"
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "LeftEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "locations-status-approved",
                                                                      size: "SM",
                                                                      checked: state.isApprovedFilter.includes("Approved"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleApprovalFilter",
                                                                                _0: "Approved"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Approved",
                                                                      className: css.label,
                                                                      htmlFor: "locations-status-approved"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "locations-status-pending",
                                                                      size: "SM",
                                                                      checked: state.isApprovedFilter.includes("Pending"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleApprovalFilter",
                                                                                _0: "Pending"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Pending",
                                                                      className: css.label,
                                                                      htmlFor: "locations-status-pending"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(DashboardLocationsIndexOptions.make, {
                                              query: tmp,
                                              sortBy: state.sortBy,
                                              approvalStatuses: state.isApprovedFilter,
                                              providerIds: state.providerIds,
                                              toggleCsvModal: (function (param) {
                                                  dispatch("ToggleCsvModalOpen");
                                                })
                                            })
                                      ],
                                      className: css.headerControls
                                    })
                              ],
                              className: css.subHeader
                            }),
                        JsxRuntime.jsxs(Table.make, {
                              className: css.table,
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Checkbox.make, {
                                                          id: "location--all--selected",
                                                          size: "SM",
                                                          checked: tmp$1,
                                                          onChange: (function (param) {
                                                              dispatch("ToggleSelectionOfAllItems");
                                                            })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Name"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Location Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "ProviderName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Provider",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "ProviderName" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Approved"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Approved?",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "Approved" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "LastApproved"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Last Approved",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$5 === "object" && match$5.NAME === "LastApproved" ? match$5.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "CreatedAt"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Created At",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$6 === "object" && match$6.NAME === "CreatedAt" ? match$6.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Active"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Active",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$7 === "object" && match$7.NAME === "Active" ? match$7.VAL : undefined
                                                                      })
                                                                ],
                                                                className: css.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp$2
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: css.pagination
                            })
                      ]
                    }),
                tmp$3,
                state.csvModalOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                        title: "Locations CSV Export",
                        message: "Please check your email for your locations export.",
                        buttonText: "Close",
                        onClick: (function (param) {
                            dispatch("ToggleCsvModalOpen");
                          })
                      }) : null
              ]
            });
}

var $$default = DashboardLocationsIndex$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
